@if (notification) {
  <div class="media mt-4 p-bg-gray-100 rounded-3">
    <div class="media-body">
      <div class="row">
        <div class="col-span-1 flex justify-center p-bg-primary rounded-3">
          <i class="mdi mdi-party-popper text-white font-size-24"></i>
        </div>
        <div class="col-span-11">
          <div class="font-size-14 text-muted">
            <a target="_blank"
               class="text-dark font-normal"
               [routerLink]="notification.link"
            >
              {{ notification.text }}
            </a>
          </div>
          <div class="font-size-12 text-muted">
            <span>{{ notification.creationTime | date: 'dd.MM.yyyy HH:mm' }}</span>
            <i style="cursor: 'pointer'" class="pi pi-trash p-color-primary ms-2 font-size-18"
               (click)="readNotification(notification.id)"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
}
