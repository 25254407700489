import {Component, Input, OnInit} from '@angular/core';
import {ShiftService} from '../../../services/shift.service';
import {TableModule} from 'primeng/table';
import {SharedModule} from 'primeng/api';
import {NgIf} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    selector: 'app-shift-dispatch-total-childs',
    templateUrl: './shift-dispatch-total-childs.component.html',
    styleUrls: ['./shift-dispatch-total-childs.component.scss'],
    standalone: true,
    imports: [TableModule, SharedModule, NgIf, TranslateModule]
})
export class ShiftDispatchTotalChildsComponent implements OnInit {

  @Input()
  shiftId: any;
  shifts: any;

  @Input()
  isOrganizer! : boolean;
  constructor(
    private shiftService: ShiftService
  ) { }

  ngOnInit(): void {
  }

}
