import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {GmapsComponent} from './gmaps/gmaps.component';
import {TableModule} from 'primeng/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {DropdownModule} from 'primeng/dropdown';
import {InputMaskModule} from 'primeng/inputmask';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule} from 'primeng/calendar';
import {TranslateModule} from '@ngx-translate/core';
import {AccordionModule} from 'primeng/accordion';
import {DragDropModule} from 'primeng/dragdrop';
import {SkeletonModule} from 'primeng/skeleton';
import {TabViewModule} from 'primeng/tabview';
import {CardModule} from 'primeng/card';
import {AvatarModule} from 'primeng/avatar';
import {TagModule} from 'primeng/tag';
import {InputNumberModule} from 'primeng/inputnumber';
import {BadgeModule} from 'primeng/badge';
import {RadioButtonModule} from 'primeng/radiobutton';
import {MessagesModule} from 'primeng/messages';
import {CheckboxModule} from 'primeng/checkbox';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {QRCodeModule} from 'angularx-qrcode';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {HeaderTopComponent} from "./header-top/header-top.component";
import {BreadcrumbModule} from "primeng/breadcrumb";
import {MenuModule} from "primeng/menu";
import {TooltipModule} from "primeng/tooltip";
import {FileUploadModule} from "primeng/fileupload";
import {ConfirmDialog, ConfirmDialogModule} from 'primeng/confirmdialog';
import {DialogModule} from 'primeng/dialog';
import {DialogService, DynamicDialogModule} from 'primeng/dynamicdialog';
import {ShiftChildManageListComponent} from '../shift/shift-child-manage-list/shift-child-manage-list.component';
import {NotificationItemComponent} from './notification-item/notification-item.component';
import {AssignmentProgressComponent} from './assignment-progress/assignment-progress.component';
import {
  ShiftDispatchTotalChildsComponent
} from '../shift/components/shift-dispatch-total-childs/shift-dispatch-total-childs.component';
// import {FlatfileAdapterModule} from '@flatfile/angular';
// import {UsecsvAngularPluginModule} from '@usecsv/angular';
import {DataViewModule} from "primeng/dataview";
import {InputSwitchModule} from "primeng/inputswitch";
import {TimelineModule} from "primeng/timeline";
import {ProgressSpinnerModule} from "primeng/progressspinner";


@NgModule({
    providers: [DatePipe, MessageService, ConfirmationService, DialogService],
    exports: [
        CommonModule,
        GmapsComponent,
        TableModule,
        ReactiveFormsModule,
        InputTextModule,
        DropdownModule,
        InputMaskModule,
        MultiSelectModule,
        CalendarModule,
        TranslateModule,
        AccordionModule,
        DragDropModule,
        SkeletonModule,
        TabViewModule,
        CardModule,
        AvatarModule,
        TagModule,
        InputNumberModule,
        BadgeModule,
        RadioButtonModule,
        MessagesModule,
        FormsModule,
        CheckboxModule,
        InputTextareaModule,
        QRCodeModule,
        ToastModule,
        HeaderTopComponent,
        MenuModule,
        TooltipModule,
        TableModule,
        FileUploadModule,
        ConfirmDialogModule,
        DialogModule,
        DynamicDialogModule,
        ShiftChildManageListComponent,
        NotificationItemComponent,
        ConfirmDialog,
        AssignmentProgressComponent,
        ShiftDispatchTotalChildsComponent,
        // FlatfileAdapterModule,
        DataViewModule,
        InputSwitchModule,
        TimelineModule,
        ProgressSpinnerModule
    ],
    imports: [
        CommonModule,
        CardModule,
        BreadcrumbModule,
        MenuModule,
        TooltipModule,
        TableModule,
        FileUploadModule,
        DropdownModule,
        TranslateModule,
        InputNumberModule,
        FormsModule,
        ConfirmDialogModule,
        // FlatfileAdapterModule,
        ToastModule,
        // UsecsvAngularPluginModule,
        DataViewModule,
        ProgressSpinnerModule,
        GmapsComponent,
        HeaderTopComponent,
        ShiftChildManageListComponent,
        NotificationItemComponent,
        AssignmentProgressComponent,
        ShiftDispatchTotalChildsComponent,
    ]
})
export class SharedModule { }
