import {Injectable} from '@angular/core';
import {FormArray} from '@angular/forms';
import {
  AvailableShiftsForAssignmentGQL,
  ChildShiftsGQL,
  CreateShiftsGQL,
  CreateShiftsInput,
  QuoteItemFilterInput,
  ShiftCurrentQuantityGQL,
  ShiftFilterInput,
  ShiftGQL,
  ShiftsGQL,
} from '../../generated/graphql';
import {EventService} from '../event/event.service';


export interface ShiftDispatchTotalModel {
  shiftId: string;
  childs: FormArray;
}

@Injectable({
  providedIn: 'root'
})
export class ShiftService {

  dispatchForms: ShiftDispatchTotalModel[] = [];
  shiftForms: any[] = [];

  public selectedShifts: any[] = [];
  constructor(
    public shiftGQL: ShiftGQL,
    public shiftsGQL: ShiftsGQL,
    private createShiftsGQL: CreateShiftsGQL,
    private eventService: EventService,
    public shiftCurrentQuantityGQL: ShiftCurrentQuantityGQL,
    private childShiftsGQL : ChildShiftsGQL,
    private availableForAssignmentGQL: AvailableShiftsForAssignmentGQL
  ) { }


  getAvailableForAssignment(eventId: string, employeeId: string) {
    return this.availableForAssignmentGQL.watch({
      eventId, employeeId
    }).valueChanges
  }

  getShift(id: string, quoteItemWhere?: QuoteItemFilterInput,options?: any ) {
    return this.shiftGQL.watch({
      where: {
        id: {
          eq: id
        }
      },
      quoteItemWhere,
    },options).valueChanges;
  }

  getChilds(id: string) {
    return this.childShiftsGQL.watch({
      shiftId: id
    }).valueChanges
  }


  getCurrentQuantity(id: string) {
    return this.shiftCurrentQuantityGQL.watch({
      id
    }).valueChanges
  }

  getShifts(where?: ShiftFilterInput) {
    return this.shiftsGQL.watch({
      where
    }).valueChanges;
  }

  createShifts(input: CreateShiftsInput) {
    return this.createShiftsGQL.mutate({
      input
    },{
      refetchQueries: [
        {
          query: this.eventService.shiftByFloorGQL.document, variables: {
            id: input.eventId
          }
        }
      ]
    })
  }
}
