import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {EventService} from '../../event/event.service';
import {NgIf} from '@angular/common';
import {TooltipModule} from 'primeng/tooltip';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@Component({
    selector: 'app-assignment-progress',
    templateUrl: './assignment-progress.component.html',
    styleUrls: ['./assignment-progress.component.scss'],
    standalone: true,
    imports: [NgIf, TooltipModule, ProgressSpinnerModule]
})
export class AssignmentProgressComponent implements OnInit, OnDestroy {

  @Input()
  progress: any;

  @Input()
  eventId!: string;

  sub: Subscription = new Subscription();

  constructor(
    private eventService: EventService
  ) { }

  ngOnInit(): void {
    if(this.eventId) {
      this.sub = this.eventService.getAssignmentProgress(this.eventId).subscribe((res) => {
        this.progress = res
      })
    }
  }

  ngOnDestroy() {
    if(this.sub) {
      this.sub.unsubscribe()
    }
  }

  getTooltip(color: string) {
    if(color === 'success') {
      return 'Alle Mitarbeiter veröffentlicht'
    }
    return 'Mitarbeiter nicht veröffentlicht'
  }

}
