<p-table class="table-alternate" dataKey="id" [value]="shifts">
  <ng-template pTemplate="header">
    <tr>
      <th *ngIf="!isOrganizer">Niederlassung</th>
      <th *ngIf="isOrganizer">Kunde/Niederlassung</th>
      <th>{{'COMMON.QUANTITY' | translate}}</th>
      <th></th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-shift let-index="rowIndex">
    <tr>
      <td *ngIf="!isOrganizer">{{shift.branch?.name}}</td>
      <td *ngIf="isOrganizer">{{shift.customer?.name}} {{shift.branch?.name}}</td>
      <td>{{shift.quantity}}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="6">Keine Schichten ausgewählt</td>
    </tr>
  </ng-template>
</p-table>

<!--<p-table class="table-alternate" dataKey="id" [value]="childs" *ngIf="childs.length > 0">-->
<!--  <ng-template pTemplate="header">-->
<!--    <tr>-->
<!--      <th>Niederlassung/Mitarbeiter</th>-->
<!--      <th>Typ</th>-->
<!--      <th>{{'COMMON.QUANTITY' | translate}}</th>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--  <ng-template pTemplate="body" let-shift let-index="rowIndex">-->
<!--    <tr>-->
<!--      <td>-->
<!--        <span *ngIf="isOrganizer">{{shift.account}}</span>-->
<!--        <span *ngIf="!isOrganizer">{{shift.branch}}</span>-->
<!--      </td>-->
<!--      <td>{{shift.type | translate}}</td>-->
<!--      <td>{{shift.quantity}}</td>-->
<!--    </tr>-->
<!--  </ng-template>-->
<!--</p-table>-->

