import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { Injectable, inject, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, ContentChildren, NgModule } from '@angular/core';
import { PrimeTemplate, SharedModule } from 'primeng/api';
import { BaseStyle } from 'primeng/base';
import { BaseComponent } from 'primeng/basecomponent';
const _c0 = (a0, a1, a2, a3, a4, a5, a6) => ({
  "p-timeline p-component": true,
  "p-timeline-left": a0,
  "p-timeline-right": a1,
  "p-timeline-top": a2,
  "p-timeline-bottom": a3,
  "p-timeline-alternate": a4,
  "p-timeline-vertical": a5,
  "p-timeline-horizontal": a6
});
const _c1 = a0 => ({
  $implicit: a0
});
function Timeline_div_1_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Timeline_div_1_ng_container_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Timeline_div_1_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, Timeline_div_1_ng_container_4_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const event_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.markerTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, event_r1));
  }
}
function Timeline_div_1_ng_template_5_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 10);
  }
  if (rf & 2) {
    i0.ɵɵattribute("data-pc-section", "marker");
  }
}
function Timeline_div_1_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 11);
  }
}
function Timeline_div_1_ng_container_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function Timeline_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 3)(1, "div", 4);
    i0.ɵɵtemplate(2, Timeline_div_1_ng_container_2_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 6);
    i0.ɵɵtemplate(4, Timeline_div_1_ng_container_4_Template, 2, 4, "ng-container", 7)(5, Timeline_div_1_ng_template_5_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(7, Timeline_div_1_div_7_Template, 1, 0, "div", 8);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(8, "div", 9);
    i0.ɵɵtemplate(9, Timeline_div_1_ng_container_9_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const event_r1 = ctx.$implicit;
    const last_r3 = ctx.last;
    const marker_r4 = i0.ɵɵreference(6);
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵattribute("data-pc-section", "event");
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-pc-section", "opposite");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.oppositeTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(11, _c1, event_r1));
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-pc-section", "separator");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r1.markerTemplate)("ngIfElse", marker_r4);
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", !last_r3);
    i0.ɵɵadvance();
    i0.ɵɵattribute("data-pc-section", "content");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.contentTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(13, _c1, event_r1));
  }
}
const theme = ({
  dt
}) => `
.p-timeline {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.p-timeline-left .p-timeline-event-opposite {
    text-align: right;
}

.p-timeline-left .p-timeline-event-content {
    text-align: left;
}

.p-timeline-right .p-timeline-event {
    flex-direction: row-reverse;
}

.p-timeline-right .p-timeline-event-opposite {
    text-align: left;
}

.p-timeline-right .p-timeline-event-content {
    text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) {
    flex-direction: row-reverse;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-opposite {
    text-align: right;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(odd) .p-timeline-event-content {
    text-align: left;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-opposite {
    text-align: left;
}

.p-timeline-vertical.p-timeline-alternate .p-timeline-event:nth-child(even) .p-timeline-event-content {
    text-align: right;
}

.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline-vertical .p-timeline-event-content {
    padding: ${dt('timeline.vertical.event.content.padding')};
}

.p-timeline-vertical .p-timeline-event-connector {
    width: ${dt('timeline.event.connector.size')};
}

.p-timeline-event {
    display: flex;
    position: relative;
    min-height: ${dt('timeline.event.min.height')};
}

.p-timeline-event:last-child {
    min-height: 0;
}

.p-timeline-event-opposite {
    flex: 1;
}

.p-timeline-event-content {
    flex: 1;
}

.p-timeline-event-separator {
    flex: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.p-timeline-event-marker {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    align-self: baseline;
    border-width: ${dt('timeline.event.marker.border.width')};
    border-style: solid;
    border-color: ${dt('timeline.event.marker.border.color')};
    border-radius: ${dt('timeline.event.marker.border.radius')};
    width: ${dt('timeline.event.marker.size')};
    height: ${dt('timeline.event.marker.size')};
    background: ${dt('timeline.event.marker.background')};
}

.p-timeline-event-marker::before {
    content: " ";
    border-radius: ${dt('timeline.event.marker.content.border.radius')};
    width: ${dt('timeline.event.marker.content.size')};
    height:${dt('timeline.event.marker.content.size')};
    background: ${dt('timeline.event.marker.content.background')};
}

.p-timeline-event-marker::after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: ${dt('timeline.event.marker.border.radius')};
    box-shadow: ${dt('timeline.event.marker.content.inset.shadow')};
}

.p-timeline-event-connector {
    flex-grow: 1;
    background: ${dt('timeline.event.connector.color')};
}

.p-timeline-horizontal {
    flex-direction: row;
}

.p-timeline-horizontal .p-timeline-event {
    flex-direction: column;
    flex: 1;
}

.p-timeline-horizontal .p-timeline-event:last-child {
    flex: 0;
}

.p-timeline-horizontal .p-timeline-event-separator {
    flex-direction: row;
}

.p-timeline-horizontal .p-timeline-event-connector {
    width: 100%;
    height: ${dt('timeline.event.connector.size')};
}

.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline-horizontal .p-timeline-event-content {
    padding: ${dt('timeline.horizontal.event.content.padding')};
}

.p-timeline-horizontal.p-timeline-alternate .p-timeline-event:nth-child(even) {
    flex-direction: column-reverse;
}

.p-timeline-bottom .p-timeline-event {
    flex-direction: column-reverse;
}
`;
const classes = {
  root: ({
    props
  }) => ['p-timeline p-component', 'p-timeline-' + props.align, 'p-timeline-' + props.layout],
  event: 'p-timeline-event',
  eventOpposite: 'p-timeline-event-opposite',
  eventSeparator: 'p-timeline-event-separator',
  eventMarker: 'p-timeline-event-marker',
  eventConnector: 'p-timeline-event-connector',
  eventContent: 'p-timeline-event-content'
};
class TimelineStyle extends BaseStyle {
  name = 'timeline';
  theme = theme;
  classes = classes;
  static ɵfac = /* @__PURE__ */(() => {
    let ɵTimelineStyle_BaseFactory;
    return function TimelineStyle_Factory(__ngFactoryType__) {
      return (ɵTimelineStyle_BaseFactory || (ɵTimelineStyle_BaseFactory = i0.ɵɵgetInheritedFactory(TimelineStyle)))(__ngFactoryType__ || TimelineStyle);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: TimelineStyle,
    factory: TimelineStyle.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimelineStyle, [{
    type: Injectable
  }], null, null);
})();

/**
 * Timeline visualizes a series of chained events.
 * @group Components
 */
class Timeline extends BaseComponent {
  /**
   * An array of events to display.
   * @group Props
   */
  value;
  /**
   * Inline style of the component.
   * @group Props
   */
  style;
  /**
   * Style class of the component.
   * @group Props
   */
  styleClass;
  /**
   * Position of the timeline bar relative to the content. Valid values are "left", "right" for vertical layout and "top", "bottom" for horizontal layout.
   * @group Props
   */
  align = 'left';
  /**
   * Orientation of the timeline.
   * @group Props
   */
  layout = 'vertical';
  templates;
  contentTemplate;
  oppositeTemplate;
  markerTemplate;
  _componentStyle = inject(TimelineStyle);
  constructor() {
    super();
  }
  getBlockableElement() {
    return this.el.nativeElement.children[0];
  }
  ngAfterContentInit() {
    this.templates.forEach(item => {
      switch (item.getType()) {
        case 'content':
          this.contentTemplate = item.template;
          break;
        case 'opposite':
          this.oppositeTemplate = item.template;
          break;
        case 'marker':
          this.markerTemplate = item.template;
          break;
      }
    });
  }
  static ɵfac = function Timeline_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Timeline)();
  };
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: Timeline,
    selectors: [["p-timeline"]],
    contentQueries: function Timeline_ContentQueries(rf, ctx, dirIndex) {
      if (rf & 1) {
        i0.ɵɵcontentQuery(dirIndex, PrimeTemplate, 4);
      }
      if (rf & 2) {
        let _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.templates = _t);
      }
    },
    inputs: {
      value: "value",
      style: "style",
      styleClass: "styleClass",
      align: "align",
      layout: "layout"
    },
    features: [i0.ɵɵProvidersFeature([TimelineStyle]), i0.ɵɵInheritDefinitionFeature],
    decls: 2,
    vars: 15,
    consts: [["marker", ""], [3, "ngStyle", "ngClass"], ["class", "p-timeline-event", 4, "ngFor", "ngForOf"], [1, "p-timeline-event"], [1, "p-timeline-event-opposite"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [1, "p-timeline-event-separator"], [4, "ngIf", "ngIfElse"], ["class", "p-timeline-event-connector", 4, "ngIf"], [1, "p-timeline-event-content"], [1, "p-timeline-event-marker"], [1, "p-timeline-event-connector"]],
    template: function Timeline_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 1);
        i0.ɵɵtemplate(1, Timeline_div_1_Template, 10, 15, "div", 2);
        i0.ɵɵelementEnd();
      }
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.styleClass);
        i0.ɵɵproperty("ngStyle", ctx.style)("ngClass", i0.ɵɵpureFunction7(7, _c0, ctx.align === "left", ctx.align === "right", ctx.align === "top", ctx.align === "bottom", ctx.align === "alternate", ctx.layout === "vertical", ctx.layout === "horizontal"));
        i0.ɵɵattribute("data-pc-name", "timeline")("data-pc-section", "root");
        i0.ɵɵadvance();
        i0.ɵɵproperty("ngForOf", ctx.value);
      }
    },
    dependencies: [i1.NgClass, i1.NgForOf, i1.NgIf, i1.NgTemplateOutlet, i1.NgStyle],
    encapsulation: 2,
    changeDetection: 0
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Timeline, [{
    type: Component,
    args: [{
      selector: 'p-timeline',
      template: `
        <div
            [class]="styleClass"
            [ngStyle]="style"
            [ngClass]="{
                'p-timeline p-component': true,
                'p-timeline-left': align === 'left',
                'p-timeline-right': align === 'right',
                'p-timeline-top': align === 'top',
                'p-timeline-bottom': align === 'bottom',
                'p-timeline-alternate': align === 'alternate',
                'p-timeline-vertical': layout === 'vertical',
                'p-timeline-horizontal': layout === 'horizontal',
            }"
            [attr.data-pc-name]="'timeline'"
            [attr.data-pc-section]="'root'"
        >
            <div *ngFor="let event of value; let last = last" class="p-timeline-event" [attr.data-pc-section]="'event'">
                <div class="p-timeline-event-opposite" [attr.data-pc-section]="'opposite'">
                    <ng-container *ngTemplateOutlet="oppositeTemplate; context: { $implicit: event }"></ng-container>
                </div>
                <div class="p-timeline-event-separator" [attr.data-pc-section]="'separator'">
                    <ng-container *ngIf="markerTemplate; else marker">
                        <ng-container *ngTemplateOutlet="markerTemplate; context: { $implicit: event }"></ng-container>
                    </ng-container>
                    <ng-template #marker>
                        <div class="p-timeline-event-marker" [attr.data-pc-section]="'marker'"></div>
                    </ng-template>
                    <div *ngIf="!last" class="p-timeline-event-connector"></div>
                </div>
                <div class="p-timeline-event-content" [attr.data-pc-section]="'content'">
                    <ng-container *ngTemplateOutlet="contentTemplate; context: { $implicit: event }"></ng-container>
                </div>
            </div>
        </div>
    `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      providers: [TimelineStyle]
    }]
  }], () => [], {
    value: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    align: [{
      type: Input
    }],
    layout: [{
      type: Input
    }],
    templates: [{
      type: ContentChildren,
      args: [PrimeTemplate]
    }]
  });
})();
class TimelineModule {
  static ɵfac = function TimelineModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TimelineModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: TimelineModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule, SharedModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TimelineModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      exports: [Timeline, SharedModule],
      declarations: [Timeline]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { Timeline, TimelineModule };
